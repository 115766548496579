import firebase from "../services/firebase";

export type Submission = {
  id: string;
  approved: boolean;
  file: string;
  url?: string;
  uid: string;
};

export class PaginatedSubmissions {
  private readonly pageSize: number;

  private startAfter?: firebase.firestore.DocumentSnapshot;
  private maxSize = 0;
  private items = 0;

  constructor(pageSize: number) {
    this.pageSize = pageSize;
  }

  async get() {
    let query = firebase
      .firestore()
      .collection("submissions")
      .where("approved", "==", true)
      .orderBy("createdAt", "desc")
      .limit(this.pageSize);

    if (this.startAfter) {
      console.log("startAfter");
      query = query.startAfter(this.startAfter);
    }

    const snapshot = await query.get();
    this.startAfter = snapshot.docs[snapshot.docs.length - 1];
    this.items += snapshot.docs.length;
    this.maxSize += this.pageSize;

    return snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() } as Submission)
    );
  }

  get hasMore() {
    return this.items === this.maxSize;
  }
}
