import React from "react";
import style from "./submissions.module.scss";
import Loader from "../components/Loader";
import { Link } from "../components/Button";
import { Submission, PaginatedSubmissions } from "../data/submissions";

type SubmissionsProps = {};

type SubmissionsState = {
  isLoading: boolean;
  items: Submission[];
};

export class Submissions extends React.Component<
  SubmissionsProps,
  SubmissionsState
> {
  private service = new PaginatedSubmissions(100);

  constructor(props: SubmissionsProps) {
    super(props);
    this.state = {
      isLoading: true,
      items: [],
    };
    this.loadMore = this.loadMore.bind(this);
  }

  async componentDidMount() {
    const items = await this.service.get();
    this.setState({
      isLoading: false,
      items: items.filter((item) => item.url),
    });
  }

  loadMore() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true }, async () => {
        const items = await this.service.get();
        this.setState((state) => ({
          isLoading: false,
          items: [...state.items, ...items.filter((item) => item.url)],
        }));
      });
    }
  }

  render() {
    return (
      <div>
        <div className={style.grid}>
          {this.state.items.map((item) => (
            <div key={item.file} className={style.item}>
              <img src={item.url} alt="Tribute" />
            </div>
          ))}
        </div>
        <div className={style.options}>
          {this.state.isLoading && <Loader />}
          {this.service.hasMore && !this.state.isLoading && (
            <Link onClick={this.loadMore}>Load more</Link>
          )}
        </div>
      </div>
    );
  }
}
