import React from "react";
import style from "./editor.module.scss";
import { Renderer } from "./textImage";
import { Button } from "./Button";

type EditorProps = {
  disabled: boolean;
  onSave: (blob: Blob) => Promise<any>;
};

type EditorState = {
  text: string;
};

const MaxLength = 100;
const SampleText = "Make your own tribute";

export class Editor extends React.Component<EditorProps, EditorState> {
  private readonly canvasRef = React.createRef<HTMLCanvasElement>();

  private renderer?: Renderer;

  public get canvas() {
    return this.canvasRef.current!;
  }

  constructor(props: any) {
    super(props);
    this.state = {
      text: SampleText,
    };
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    // @ts-ignore
    if (document.fonts && document.fonts.ready) {
      // @ts-ignore
      await document.fonts.ready;
    }

    const colors = ["#ECE9D8", "#990000"];
    if (Math.random() > 0.5) {
      colors.reverse();
    }

    this.renderer = new Renderer(this.canvas, {
      backgroundColor: colors[1],
      color: colors[0],
      font: "Roboto Condensed",
      padding: 80,
      maxSize: 120,
    });
    this.renderer.render(`"${SampleText}"`);
  }

  parseText(value: string) {
    if (value.length > MaxLength) {
      value = value.substr(0, MaxLength);
    }
    return value.replace(/(\r|\n)/gm, "");
  }

  onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    try {
      const textarea = event.target as HTMLTextAreaElement;
      const value = this.parseText(textarea.value);
      this.renderer?.render(`"${value}"`);
      this.setState({ text: value });
    } catch (err) {
      // Do nothing
    }
  }

  getBlob() {
    return new Promise<Blob>((resolve, reject) => {
      this.canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(Error("Error saving"));
        }
      }, "image/jpeg");
    });
  }

  async onSave() {
    try {
      const blob = await this.getBlob();
      await this.props.onSave(blob);
    } catch (err) {
      // @todo: Handle
      alert(
        "We're having troubling save your tribute, perhaps you're using an old browser?"
      );
    }
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.viewport}>
          <canvas
            ref={this.canvasRef}
            className={style.canvas}
            width={800}
            height={800}
          ></canvas>
        </div>
        <div className={style.options}>
          <div className={style.label}>
            <span>Message</span>
            <span className={style.counter}>
              {this.state.text.length} / {MaxLength}
            </span>
          </div>
          <textarea
            rows={4}
            disabled={this.props.disabled}
            className={style.textarea}
            value={this.state.text}
            onChange={this.onChange}
          />
        </div>
        <p>
          <Button disabled={this.props.disabled} onClick={this.onSave}>
            Submit tribute
          </Button>
        </p>
      </div>
    );
  }
}
