import React from "react";
import {
  withRouter,
  Switch,
  Route,
  NavLink,
  RouteComponentProps,
} from "react-router-dom";
import { User } from "firebase";

import { Editor } from "../../components/Editor";
import { FilePicker } from "../../components/FilePicker";
import { NavButton } from "../../components/Button";

import firebase from "../../services/firebase";
import style from "./index.module.scss";

const randomString = () => Math.random().toString(36).substring(6);

const upload = async (blob: Blob) => {
  await firebase
    .storage()
    .ref()
    .child(`submissions/${randomString()}.jpg`)
    .put(blob);
};

// const uploadBlob = async (blob: Blob, user: User) => {
//   try {
//     const snapshot = await firebase
//       .storage()
//       .ref()
//       .child(`${user.uid}/${randomString()}.png`)
//       .put(blob);
//   } catch (err) {
//     console.log(err);
//   }
// };

type UploaderState = {
  isLoading: boolean;
  user: User | null;
};

class Index extends React.Component<RouteComponentProps, UploaderState> {
  private unsubscribe?: firebase.Unsubscribe;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      user: null,
    };
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (!user) {
        // firebase.auth().signInAnonymously();
      } else {
      }
      this.setState({ isLoading: false, user });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  async onSave(blob: Blob) {
    // if (!this.state.user) {
    //   return;
    // }

    try {
      this.setState({ isLoading: true });
      // await uploadBlob(blob, this.state.user);
      await upload(blob);
      this.props.history.push("/upload/confirm");
    } catch (err) {
      // Do something
      alert("Uploading failed");
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className={style.container}>
        <Switch>
          <Route path="/upload/text">
            <h1>Create message</h1>
            <Editor onSave={this.onSave} disabled={this.state.isLoading} />
            <NavLink to="/upload">Back</NavLink>
          </Route>
          <Route path="/upload/image">
            <h1>Upload image</h1>
            <FilePicker onSave={this.onSave} disabled={this.state.isLoading} />
            <NavLink to="/upload">Back</NavLink>
          </Route>
          <Route path="/upload/confirm">
            <h1>Almost there!</h1>
            <p>Your submission will be reviewed and visible online shortly!</p>
            <p>
              <NavButton to="/">Back to gallery</NavButton>
            </p>
          </Route>
          <Route>
            <h1>Pay tribute</h1>
            <p>
              Awesome that you want to join in paying tribute! What would you
              like to add?
            </p>
            <p>
              <NavButton to="/upload/image">Image</NavButton>
              <NavButton to="/upload/text">Message</NavButton>
            </p>
            <NavLink to="/">Back to gallery</NavLink>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Index);
