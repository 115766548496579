import React from "react";
import { Submissions } from "./Submissions";
import { NavButton } from "../components/Button";
import style from "./index.module.scss";

class Index extends React.Component {
  render() {
    return (
      <div className={style.container}>
        <div className={style.header}>
          <h1>Candlelight 2020</h1>
          <p>
            ElvisMatters presents an online tribute board to commemorate the
            greatest rock and roll artist of all time!
          </p>
          <div className={style.options}>
            <NavButton to="/upload">Pay tribute</NavButton>
          </div>
        </div>
        <Submissions />
        <p className={style.footer}>
          &copy; <a href="http://elvismatters.com/">Elvis Matters 2020</a>
          <br />
          Made with ❤️ by{" "}
          <a href="https://vierkanteoogjes.nl/">Vierkante Oogjes</a>
        </p>
      </div>
    );
  }
}

export default Index;
