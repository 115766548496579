export type RenderOptions = {
  backgroundColor: string;
  color: string;
  debug: boolean;
  lineHeight: number;
  font: string;
  padding: number;
  maxSize: number;
};

const defaults: RenderOptions = {
  backgroundColor: "#fff",
  color: "#000",
  debug: false,
  padding: 60,
  font: "Arial",
  lineHeight: 1.2,
  maxSize: 72,
};

type Box = {
  height: number;
  text: string;
  width: number;
};

type PlacedBox = {
  x: number;
  y: number;
} & Box;

type Line = PlacedBox[];

export class Renderer {
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly options: RenderOptions;

  constructor(canvas: HTMLCanvasElement, options?: Partial<RenderOptions>) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.options = {
      ...defaults,
      ...options,
    };
  }

  getMaxWidth() {
    return this.canvas.width - this.options.padding * 2;
  }

  getMaxHeight() {
    return this.canvas.height - this.options.padding * 2;
  }

  convertBoxesToLines(boxes: Box[], spacing: number) {
    const maxWidth = this.getMaxWidth();
    let line: Line = [];
    let lines: Line[] = [line];
    let lineWidth = 0;

    boxes.forEach((box) => {
      if (lineWidth + box.width > maxWidth && line.length > 0) {
        line = [];
        lines.push(line);
        lineWidth = 0;
      }

      const width = box.width + spacing;
      line.push({
        ...box,
        width,
        x: lineWidth,
        y: (lines.length - 1) * box.height,
      });
      lineWidth += width;
    });

    if (lines.length > 0) {
      lines = lines.map((line) => {
        line[line.length - 1].width -= spacing;
        return line;
      });
    }

    return lines;
  }

  calculateLines(size: number, words: string[]) {
    this.ctx.font = `${size}px '${this.options.font}'`;
    const height = size * this.options.lineHeight;
    const boxes = words.map((word) => {
      const metrics = this.ctx.measureText(word);
      return {
        text: word,
        width: metrics.width,
        height: height,
      };
    });
    return this.convertBoxesToLines(boxes, size * 0.25);
  }

  draw(lines: Line[], size: number) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = this.options.backgroundColor;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.font = `${size}px '${this.options.font}'`;
    this.ctx.fillStyle = this.options.color;

    const height = lines.reduce((acc, line) => (acc += line[0].height), 0);
    const offsetY = (this.canvas.height - height) / 2;

    lines.forEach((line) => {
      const width = line.reduce((acc, box) => (acc += box.width), 0);
      const offsetX = (this.canvas.width - width) / 2;

      line.forEach((box) => {
        if (this.options.debug) {
          this.ctx.strokeStyle = "#ccc";
          this.ctx.strokeRect(
            box.x + offsetX,
            box.y + offsetY,
            box.width,
            box.height
          );
          this.ctx.strokeStyle = "";
        }
        this.ctx.fillText(
          box.text,
          box.x + offsetX,
          box.y + offsetY + (box.height + size * 0.66) / 2
        );
      });
    });
  }

  getMaxLineWidth(lines: Line[]) {
    return lines.reduce((acc, line) => {
      const value = line.reduce((acc, box) => (acc += box.width), 0);
      return value > acc ? value : acc;
    }, 0);
  }

  fit(words: string[]) {
    const maxHeight = this.getMaxHeight();
    const maxWidth = this.getMaxWidth();
    let size = this.options.maxSize;
    while (size > 0) {
      const lines = this.calculateLines(size, words);
      const width = this.getMaxLineWidth(lines);
      const height = lines.length * size * this.options.lineHeight;
      if (height < maxHeight && width < maxWidth) {
        console.log(size);
        return { lines, size };
      }
      size -= 2;
    }
    throw Error("Unable to resolve");
  }

  render(text: string) {
    const words = text.replace(/(\r|\n)/gm, "").split(" ");
    const { lines, size } = this.fit(words);
    this.draw(lines, size);
  }
}
