import React from "react";
import style from "./loader.module.scss";

const Loader = () => (
  <div className={style.loader}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loader;
