import React from "react";
import { User } from "firebase";

import { LoginForm, LoginCredentials } from "../../components/auth/LoginForm";
import { Link } from "../../components/Button";
import { SubmissionList } from "./SubmissionList";

import firebase from "../../services/firebase";
import style from "./admin.module.scss";

type AdminState = {
  isLoading: boolean;
  user?: User | null;
  errors: Error[];
};

class Admin extends React.Component<any, AdminState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      errors: [],
      user: undefined,
    };
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isLoading: false, user });
    });
  }

  async onLogin(credentials: LoginCredentials) {
    try {
      this.setState({ errors: [], isLoading: true });
      await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ errors: [err], isLoading: false });
    }
  }

  async onLogout() {
    try {
      await firebase.auth().signOut();
    } catch (err) {
      // Do nothing
    }
  }

  render() {
    if (this.state.user === undefined) {
      return <div>Loading</div>;
    }

    if (!this.state.user) {
      return (
        <div className={style.container}>
          <LoginForm
            disabled={this.state.isLoading}
            onSubmit={this.onLogin}
            errors={this.state.errors}
          />
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.header}>
          <span>{this.state.user.email}</span>
          <Link onClick={this.onLogout}>Logout</Link>
        </div>
        <SubmissionList />
      </div>
    );
  }
}

export default Admin;
