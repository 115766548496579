import React from "react";
import firebase from "../../services/firebase";
import style from "./submissionList.module.scss";
import { Button, Link } from "../../components/Button";
import { PaginatedSubmissions } from "../../data/submissions";

type Submission = {
  id: string;
  approved: boolean;
  file: string;
  url?: string;
  uid: string;
};

type SubmissionListState = {
  isLoading: boolean;
  approved: Submission[];
  pending: Submission[];
};

export class SubmissionList extends React.Component<any, SubmissionListState> {
  private service = new PaginatedSubmissions(100);

  constructor(props: any) {
    super(props);
    this.state = {
      approved: [],
      isLoading: true,
      pending: [],
    };
    this.onApprove = this.onApprove.bind(this);
    this.onReject = this.onReject.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  async componentDidMount() {
    firebase
      .firestore()
      .collection("submissions")
      .orderBy("createdAt", "desc")
      .where("approved", "==", false)
      .onSnapshot(async (snapshot) => {
        const items = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = { id: doc.id, ...doc.data() } as Submission;
            try {
              const url =
                data.url ||
                (await firebase.storage().ref(data.file).getDownloadURL());
              return { ...data, url };
            } catch (err) {
              // Do nothing
              return data;
            }
          })
        );

        this.setState({
          isLoading: false,
          pending: items,
        });
      });

    const items = await this.service.get();
    this.setState({ approved: items });
  }

  async onApprove(item: Submission) {
    await firebase
      .firestore()
      .collection("submissions")
      .doc(item.id)
      .update({ approved: true });
    this.setState((state) => ({
      approved: [item, ...state.approved],
    }));
  }

  async onReject(submission: Submission) {
    await firebase
      .firestore()
      .collection("submissions")
      .doc(submission.id)
      .delete();
    this.setState((state) => ({
      approved: state.approved.filter((item) => item.id !== submission.id),
    }));
  }

  async loadMore() {
    const items = await this.service.get();
    this.setState((state) => ({
      approved: [...state.approved, ...items],
    }));
  }

  render() {
    return (
      <div>
        <h2>Pending submissions</h2>
        {this.state.isLoading && "Loading"}
        <div className={style.grid}>
          {this.state.pending.map((item) => (
            <div key={item.file} className={style.item}>
              {item.url ? <img src={item.url} alt="Tribute" /> : "Unavailable"}
              <div className={style.options}>
                <Button onClick={() => this.onApprove(item)}>Approve</Button>
                <Link onClick={() => this.onReject(item)}>Reject</Link>
              </div>
            </div>
          ))}
          {this.state.pending.length === 0 && <p>No pending submissions</p>}
        </div>

        <h2>Approved submissions</h2>
        <div className={style.grid}>
          {this.state.approved.map((item) => (
            <div key={item.file} className={style.item}>
              {item.url ? <img src={item.url} alt="Tribute" /> : "Unavailable"}
              <div className={style.options}>
                <Link onClick={() => this.onReject(item)}>Delete</Link>
              </div>
            </div>
          ))}
          {this.state.approved.length === 0 && <p>No submissions</p>}
        </div>
        <div className={style.loadMore}>
          {this.service.hasMore && (
            <Link onClick={this.loadMore}>Load more</Link>
          )}
        </div>
      </div>
    );
  }
}
