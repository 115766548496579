import React from "react";
import style from "./button.module.scss";
import { NavLink as RouterLink } from "react-router-dom";

type ButtonProps = {
  children: React.ReactNode;
} & React.PropsWithoutRef<JSX.IntrinsicElements["button"]>;

export const Button = ({ children, ...props }: ButtonProps) => (
  <button {...props} className={style.button}>
    {children}
  </button>
);

type NavButtonProps = {
  children: React.ReactNode;
  to: string;
};

export const NavButton = (props: NavButtonProps) => {
  return (
    <RouterLink className={style.button} to={props.to}>
      {props.children}
    </RouterLink>
  );
};

export const Link = ({ children, ...props }: ButtonProps) => (
  <button {...props} className={style.link}>
    {children}
  </button>
);

type NavLinkProps = {
  children: React.ReactNode;
  to: string;
};

export const NavLink = ({ children, ...props }: NavLinkProps) => (
  <RouterLink className={style.link} to={props.to}>
    {children}
  </RouterLink>
);
