import React from "react";
import style from "./loginform.module.scss";
import { Button, NavLink } from "../Button";

export type LoginCredentials = {
  email: string;
  password: string;
};

type LoginFormProps = {
  disabled: boolean;
  errors: Error[];
  onSubmit: (credentials: LoginCredentials) => void;
};

export class LoginForm extends React.Component<LoginFormProps> {
  static defaultProps = { errors: [] };

  constructor(props: LoginFormProps) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    const values: Record<string, string> = {};
    const data = new FormData(event.target as HTMLFormElement);
    data.forEach((value, key) => {
      values[key] = value.toString();
    });
    this.props.onSubmit(values as LoginCredentials);
  }

  render() {
    return (
      <form className={style.form} onSubmit={this.onSubmit}>
        <h2>Login</h2>
        <fieldset className={style.fieldset} disabled={this.props.disabled}>
          {this.props.errors.map((error) => (
            <p>{error.message}</p>
          ))}
          <input type="text" name="email" placeholder="Email" />
          <input type="password" name="password" placeholder="Password" />
          <div className={style.options}>
            <NavLink to="/">Back to gallery</NavLink>
            <Button type="submit">Submit</Button>
          </div>
        </fieldset>
      </form>
    );
  }
}
