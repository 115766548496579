import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Index from "./views/Index";
import Admin from "./views/admin/Admin.tsx";
import Upload from "./views/upload/Index.tsx";

const App = () => (
  <Router>
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/upload" component={Upload} />
      <Route component={Index} />
    </Switch>
  </Router>
);

export default App;
