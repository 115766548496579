import React from "react";
import style from "./editor.module.scss";
import { Renderer } from "./image";
import { Button } from "./Button";

type FilePickerProps = {
  disabled: boolean;
  onSave: (blob: Blob) => Promise<any>;
};

type FilePickerState = {
  file?: File;
};

export class FilePicker extends React.Component<
  FilePickerProps,
  FilePickerState
> {
  private readonly canvasRef = React.createRef<HTMLCanvasElement>();

  private renderer?: Renderer;

  public get canvas() {
    return this.canvasRef.current!;
  }

  constructor(props: any) {
    super(props);
    this.state = {};
    this.onSelect = this.onSelect.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.renderer = new Renderer(this.canvas, { backgroundColor: "#eee" });
    this.renderer.render();
  }

  async onSelect(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      const { files } = event.target as HTMLInputElement;
      const file = files![0];
      if (file) {
        await this.renderer?.render(file);
        this.setState({ file });
      }
    } catch (err) {
      // Do nothing
    }
  }

  getBlob() {
    return new Promise<Blob>((resolve, reject) => {
      this.canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(Error("Error saving"));
        }
      }, "image/jpeg");
    });
  }

  async onSave() {
    try {
      const blob = await this.getBlob();
      await this.props.onSave(blob);
    } catch (err) {
      // @todo: Handle
      alert(
        "We're having troubling save your tribute, perhaps you're using an old browser?"
      );
    }
  }

  render() {
    const classNames = [style.viewport, !this.state.file && style.hide].filter(
      Boolean
    );

    return (
      <div className={style.container}>
        <div className={classNames.join(" ")}>
          <canvas
            ref={this.canvasRef}
            className={style.canvas}
            width={800}
            height={800}
          ></canvas>
        </div>
        <p className={style.uploadOptions}>
          <div
            className={style.uploader + " " + (this.state.file && style.alt)}
          >
            <span>{this.state.file ? "Change" : "Choose"} image</span>
            <input
              type="file"
              disabled={this.props.disabled}
              accept=".jpg,.png,.gif,.jpeg"
              onChange={this.onSelect}
            />
          </div>

          {this.state.file && (
            <Button
              disabled={!this.state.file || this.props.disabled}
              onClick={this.onSave}
            >
              Submit tribute
            </Button>
          )}
        </p>
      </div>
    );
  }
}
