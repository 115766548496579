export type RenderOptions = {
  backgroundColor: string;
};

const defaults: RenderOptions = { backgroundColor: "#fff" };

export class Renderer {
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly options: RenderOptions;

  constructor(canvas: HTMLCanvasElement, options?: Partial<RenderOptions>) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.options = {
      ...defaults,
      ...options,
    };
  }

  loadImage(file: File) {
    const url = window.URL.createObjectURL(file);
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.onerror = () => {
        reject(Error("Error loading image"));
      };
      image.src = url;
    });
  }

  async render(file?: File) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = this.options.backgroundColor;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    if (file) {
      const image = await this.loadImage(file);
      const scaleX = this.canvas.width / image.width;
      const scaleY = this.canvas.height / image.height;
      const scale = Math.max(scaleX, scaleY);

      const width = image.width * scale;
      const height = image.height * scale;
      const offsetX = (this.canvas.width - width) / 2;
      const offsetY = (this.canvas.height - height) / 2;
      this.ctx.drawImage(image, offsetX, offsetY, width, height);
    }
  }
}
