import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCn7bSWreoiru_w9MTJXNXEksxOO_Tz2HQ",
  authDomain: "photowall-ba698.firebaseapp.com",
  databaseURL: "https://photowall-ba698.firebaseio.com",
  projectId: "photowall-ba698",
  storageBucket: "photowall-ba698.appspot.com",
  messagingSenderId: "82230945849",
  appId: "1:82230945849:web:44e3c86280d68d083645e8",
  measurementId: "G-8EDGWB0Q88",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.analytics();

export default firebase;
